<template>
  <div class=" min-h-screen flex bg-main-color relative">
    <transition name="slide-fade">
    <div
    @click="handleLeftNavBarShow"
    v-show="leftNavShow" class="mask"></div>
    </transition>
    <transition name="swipe-fade">
    <LeftNavBar
    @leftMenuHide="handleLeftNavHide"
    v-show="leftNavShow" class="leftBar"></LeftNavBar>
    </transition>
    <div
      ref="personArea"
      class=" flex-grow sm:flex-grow max-h-screen overflow-y-auto">
      <a-back-top
      :target='scrollTarget'
      />
      <TopBanner
      @iconClick='handleLeftNavBarShow'
      ></TopBanner>
      <router-view
      @scrollTotop="scrollTotop"
      />
    </div>
  </div>
</template>

<script>
import { removeLocalStorage } from '@/utils/localStorage'
import LeftNavBar from '@/components/User/LeftNavBar.vue'
import TopBanner from '@/components/User/TopBanner.vue'

export default {
  created () {
    this.$store.dispatch('login/setUserDetailProfileAction').catch((e) => {
      this.$message.error('Please Login In')
      this.$router.push({ name: 'SmsLogin' })
      removeLocalStorage('token')
      removeLocalStorage('user')
    })
  },
  data () {
    return {
      leftNavShow: true
    }
  },
  components: {
    LeftNavBar,
    TopBanner
  },
  computed: {

  },
  methods: {
    scrollTarget () {
      return this.$refs.personArea
    },
    handleLeftNavHide () {
      this.leftNavShow = false
    },
    scrollTotop () {
      console.dir(this.$refs.personArea)
      this.$refs.personArea.scrollTo({
        top: 0
      })
    },
    handleLeftNavBarShow () {
      this.leftNavShow = !this.leftNavShow
    }
  }
}

</script>

<style scoped>
.bg-main-color{
  background: #f8fafc;
  /* background: #f5f5f5; */
}
.slide-fade-enter-active,.slide-fade-leave-active{
  transition: all 0.2s ease;
  opacity: 1;
}

.slide-fade-enter, .slide-fade-leave-to
 {
  opacity: 0;
}
.swipe-fade-enter-active ,.swipe-fade-leave-active{
  transition: all 0.2s ease;
  transform: translateX(0);
}
.swipe-fade-enter, .swipe-fade-leave-to
{
  transition: all 0.2s ease;
  transform: translateX(-100%);
}

@media only screen and (max-width: 600px) {
  .leftBar{
    position: absolute;
  }
  .ballPosition{
    right: 20%;
    bottom: 30%;
  }
  .mask{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5;
  }
}
</style>
